html {
    font-family: 
        "Segoe UI",
        "system-ui",
        -apple-system,
        BlinkMacSystemFont,
        Roboto,
        Oxygen-Sans,
        Ubuntu,
        Cantarell,
        "Helvetica Neue",
        sans-serif;
}