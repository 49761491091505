html {
  font-family: "Segoe UI", "system-ui", -apple-system, BlinkMacSystemFont, Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
.container.centered {
  display: flex;
}
.container.vertically.centered {
  align-items: center;
}
.container.horizontally.centered {
  justify-content: center;
}
html {
  background-color: #314251;
  color: #EEE;
  height: 100%;
}
body {
  height: inherit;
  margin: 0;
}
#app-container {
  height: inherit;
}
#app-logo {
  width: 422px;
  height: 421px;
  max-height: 60vh;
  background-image: url('./images/logo.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
