@component-prefix: '';
@component-name: .@{component-prefix}container;

@{component-name} {

    &.centered {
        display: flex;
    }

    &.vertically.centered {
        align-items: center;
    }

    &.horizontally.centered {
        justify-content: center;
    }
}