@import './variables.less';
@import './fonts';
@import './components/container';

html {
    background-color: @inverted-background-color;
    color: @inverted-font-color;
    height: 100%;
}

body {
    height: inherit;
    margin: 0;
}

#app-container {
    height: inherit;
}

#app-logo {
    width: 422px;
    height: 421px;
    max-height: 60vh;
    background-image: url('./images/logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}